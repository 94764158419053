<template>
  <div :class="{xs12: isExportable, xl6: isExportable}" class="flex">

    <vac-card
      v-if="isExportable"
      :title="label"
      class="chart-widget">

      <ExcelPreviewModal
        v-if="isPreview"
        :data="preparedData"
        :header="label"
        @disable="togglePreview"
        @exportToExcel="exportToExcel"
      />
      <Actions
        v-if="isExportable"
        show-percentage-switcher
        :active-switcher-button="activeSwitcherButton"
        :export-to-excel="exportToExcel"
        is-preview
        @togglePreview="togglePreview"
        @toggle-percentage="onToggle($event)"
      />


      <Doughnut
        v-if="!label.includes('categories')"
        :dataset-id-key="'label'"
        :height="700"
        :chart-options="chartOptions"
        :chart-data="data"
        :css-classes="'doughnut export'"
        :style="{height: '700px'}"
      />
      <!--      <va-chart v-if="!label.includes('categories')" :data="data" :style="{height: '500px'}" type="donut"/>-->

      <template v-else>
        <Doughnut
          :dataset-id-key="'label'"
          :height="500"
          :chart-options="chartOptionsWithoutLabels"
          :chart-data="data"
          :css-classes="'doughnut export'"
          :style="{height: '500px'}"
        />
        <!--        <vac-chart-->
        <!--                   :data="data"-->
        <!--                   :options="{legend: {display: false}}"-->
        <!--                   :style="{height: '500px'}"-->
        <!--                   type="donut"/>-->

        <div v-for="(_, key) in data['labels']" :key="key" class="labels">
          <div class="labels__wrapper">
            <div class="labels__item">
              <span :style="{backgroundColor: data['datasets'][0]['backgroundColor'][key]}" class="labels__point"/>
              <span :title="data['labels'][key]" class="labels__item-text"> {{ data['labels'][key] }} </span>
            </div>
          </div>
        </div>
      </template>
    </vac-card>

    <vac-card
      v-else
      :style="{margin: '10px 0'}"
      :title="label"
      class="chart-widget">

      <Doughnut
        v-if="!label.includes('categories')"
        :dataset-id-key="'label'"
        :height="400"
        :chart-options="chartOptions"
        :chart-data="data"
        :css-classes="'doughnut-small export'"
        :style="{height: '400px'}"
      />
      <!--      <va-chart v-if="!label.includes('categories')" :data="data" :style="{height: '400px'}" type="donut"/>-->

      <template v-else>
        <Doughnut
          :dataset-id-key="'label'"
          :height="400"
          :chart-options="chartOptionsWithoutLabels"
          :chart-data="data"
          :css-classes="'doughnut-small export'"
          :style="{height: '400px'}"
        />
        <!--        <vac-chart-->
        <!--                   :data="data"-->
        <!--                   :options="{legend: {display: false}}"-->
        <!--                   :style="{height: '400px'}"-->
        <!--                   type="donut"/>-->

        <div v-for="(_, key) in data['labels']" :key="key" class="labels">
          <div class="labels__wrapper">
            <div class="labels__item">
              <span :style="{backgroundColor: data['datasets'][0]['backgroundColor'][key]}" class="labels__point"/>
              <span :title="data['labels'][key]" class="labels__item-text"> {{ data['labels'][key] }} </span>
            </div>
          </div>
        </div>
      </template>
    </vac-card>

  </div>
</template>

<script>
import ExcelPreviewModal from "@/components/actions/ExcelPreviewModal";
import HelperExcel from '../../services/Helpers/HelperExcel';
import Actions from "../actions/Actions";
import { Doughnut } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)
export default {
  name: "TrendsPieStats",
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
    isExportable: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: "Pie Chart"
    }
  },
  components: {
    // VacChart,
    // VaChart,
    Actions,
    ExcelPreviewModal,
    Doughnut
  },
  data() {
    return {
      isPreview: false,
      activeSwitcherButton: 1
    }
  },
  computed: {
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: (ttItem) => {
                if (this.activeSwitcherButton === 1) {
                  return `${ttItem.label}: ${ttItem.parsed}`;
                } else {
                  let sum = 0;
                  let dataArr = ttItem.dataset.data;
                  dataArr.map(data => {
                    sum += Number(data);
                  });

                  let percentage = (ttItem.parsed * 100 / sum).toFixed(2) + '%';
                  return `${ttItem.label}: ${percentage}`;
                }
              }
            }
          },
          legend: {
            position: 'bottom',
            labels: {
              padding: 20,
              usePointStyle: true,
              pointStyle: 'circle'
            }
          }
        }
      }
    },
    chartOptionsWithoutLabels() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {

          legend: {
            display: false
          }
        }
      }
    },
    preparedData() {
      const {labels, datasets} = JSON.parse(JSON.stringify(this.data));
      if (this.activeSwitcherButton === 2) {
        let sum = 0
        datasets[0].data.forEach(d => {
          sum += d
        })
        datasets[0].data = datasets[0].data.map(d => {
          return `${(d * 100/sum).toFixed(2)}%`
        })
      }

      const returnObj = {}
      if (this.label.includes("categories"))
        returnObj.labels = ["Categories", "Count"]
      else
        returnObj.labels = ["Brands", "Count"]
      returnObj.maxLength = []

      for (let key = 0; key < returnObj.labels.length; key++) {
        returnObj.maxLength[key] = returnObj.labels[key].length;
      }

      returnObj.datasets = [];
      datasets.forEach(data => {
        data.data.forEach((info, index) => {
          const obj = {
            data: [labels[index], info]
          };
          returnObj.maxLength[0] = Math.max(returnObj.maxLength[0], String(labels[index]).length)
          returnObj.maxLength[1] = Math.max(returnObj.maxLength[1], String(info).length)
          returnObj.datasets.push(obj);
        });
      });
      return returnObj;
    },

  },
  methods: {
    exportToExcel() {
      const customData = [];
      const customLabels = ["Retailer", "Count"];
      const {labels, datasets} = JSON.parse(JSON.stringify(this.data));
      if (this.activeSwitcherButton === 2) {
        let sum = 0
        datasets[0].data.forEach(d => {
          sum += d
        })
        datasets[0].data = datasets[0].data.map(d => {
          return `${(d * 100/sum).toFixed(2)}%`
        })
      }

      datasets.forEach(data => {
        data.data.forEach((info, index) => {
          const obj = {
            "Retailer": labels[index],
            "Count": info,
          };
          customData.push(obj);
        });
      });

      HelperExcel.exportToExcel({}, this.label, customLabels, customData);
    },

    togglePreview() {
      this.isPreview = !this.isPreview;
    },
    onToggle(val) {
      this.activeSwitcherButton = val
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'src/sass/variables';

.labels {
  display: flex;
  align-items: center;
  width: 100%;

  &__wrapper {
    display: flex;
    width: 100%;
    margin: 5px 0;
    justify-content: space-around;
    align-items: center;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-text {
      @include clamped-text(1);
    }

    &:nth-child(2) {
      margin-left: 5px;
    }
  }

  &__point {
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
    border-radius: 100%;
    display: block;
    margin-right: 5px;
  }
}

.doughnut {
  height: 700px;
}

.doughnut-small {
  height: 400px;
}
</style>
